// Packages
import React from 'react';

// Components
import InfoSection from '../reusables/InfoSection';

// Data
import classesData from '../../data/classesData'

export default function ClassesContent() {

    const data = classesData();

    return  (
        <div className="classes-section">
            {data.map(c => 
                <InfoSection
                    bg_color="platinum-bg"
                    key={c.id}
                    title={c.title}
                    text={c.text}
                    fg_color="rich-black-fogra-fg"
                    reverse={c.reverse}
                    image={c.image}
                ></InfoSection>
            )}
        </div>
    );
}