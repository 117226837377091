// Packages
import React from 'react';

// Components
import Layout from '../components/Layout';
import PageHeader from '../components/reusables/PageHeader';
import FooterContact from '../components/reusables/FooterContact'
import ClassesContent from '../components/classes/ClassesContent';
import HeadMetadata from '../components/HeadMetadata';

export default function Classes() {
    return (
        <Layout>
            <HeadMetadata title="Classes"></HeadMetadata>
            <PageHeader
                image="patrick-teaching-kids.jpg"
                title="JOIN OUR CLASSES"
                text={`"No aspect of jiu jitsu is invented, only discovered. Every possible submission, sweep or throw already exists... progression is a matter of revelation, we have to learn to see what is there." - Francisco Mansor`}
            ></PageHeader>
            <ClassesContent></ClassesContent>
            <FooterContact
                bg_color="platinum"
                fg_color="rich-black-fogra"
            ></FooterContact>
        </Layout>
    );
}