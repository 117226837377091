import { v4 as uuidv4 } from 'uuid';

export default function classesData() {
    
    return (
        [
            {
                id: uuidv4(),
                title: "KIDS JIU JITSU",
                text: "This class is offered for children of all skill levels and ages ranging from 6 to 14 years old. Our kid’s class focuses on teaching the fundamentals of jiu-jitsu and basic self-defense. It is important to us that we provide a safe and fun environment for children to grow and learn.  Kids who partake in extracurricular activities see a significant improvement in school performance. BJJ is especially beneficial in childhood development since it helps improve confidence, team-building skills, discipline, problem solving skills and healthy habits.",
                image: "kal.jpg",
                reverse: false,
            },
            {
                id: uuidv4(),
                title: "BJJ FUNDAMENTALS",
                text: "Our fundamentals class (for ages 16 and up) focuses on teaching the core concepts and movements of jiu-jitsu. This class is recommended for new students but is open to anyone looking to refine the techniques that will be used throughout one’s entire jiu-jitsu career. A strong foundation is key in effective jiu-jitsu and therefore we recommended this class for all practitioners regardless of years of training. ",
                image: "jiu-jitsu-fundamentals.jpg",
                reverse: true
            },
            
            {
                id: uuidv4(),
                title: "ADVANCED JIU JITSU ",
                text: "This class is recommended for adult jiu-jitsu practitioners who have a solid understanding of the fundamentals of jiu-jitsu. In this class, we expand on the core concepts by breaking down important details that will improve your game as you develop your own style of jiu-jitsu. Understanding and controlling your opponents reactions, chaining movements together and learning how to stay in control of your body and mind are a few of the key elements introduced in the advanced class.",
                image: "advanced jiu jitsu.jpg",
                reverse: false
            },
            {
                id: uuidv4(),
                title: "WOMEN'S JIU JITSU",
                text: "This class is offered for women only and will cover the core fundamentals of jiu-jitsu and self-defense. Training BJJ is especially beneficial for ladies looking to gain knowledge in how to defend themselves against a larger, stronger person. This class is open for women of any skill and fitness level, ages 16 and up. Ran by a female instructor, our aim is to enhance women’s capabilities and push the limits of women in martial arts.",
                image: "women-bjj.jpg",
                reverse: true,
            },
            {
                id: uuidv4(),
                title: "NO GI GRAPPLING",
                text: "No gi is a branch of jiu-jitsu practiced wearing rash guards and some form of sports shorts/pants. No gi grappling has been popularized in sports like mixed martial arts (MMA) since this form of jiu-jitsu does not rely on a traditional gi to submit your opponent. In this class, we also supplement in other forms of grappling such as wrestling, sambo etc. and apply it to a no gi jiu-jitsu environment.",
                image: "no-gi.jpg",
                reverse: false
            },
            {
                id: uuidv4(),
                title: "COMBAT JIU JITSU",
                text: "Combat jiu-jitsu is a form of no gi grappling that includes open palm strikes. This form of jiu-jitsu is the middle ground between jiu-jitsu and MMA. In combat jiu-jitsu, we do not practice stand up striking or kicking. The focus of this class is to teach important distancing techniques that translate over to non combat jiu-jitsu, general self-defense and how/when to strike in combat jiu-jitsu. This class also includes a live-sparring session.",
                image: "mikey-umbar.jpg",
                reverse: true,
            },
            {
                id: uuidv4(),
                title: "OPEN MAT",
                text: "Our open mat is live sparring class with no formal instruction. All members and drop-in guests of any age and skill level are welcome to partake in this class. We encourage all practitioners to partake in open mats, as it is an excellent chance to learn from higher belts and practice your jiu-jitsu. The more time you spend on the mats the more your jiu-jitsu will develop. ",
                image: "open_mat.jpg",
                reverse: false,
            },
        ]
    );
}