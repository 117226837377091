// Packages
import React from 'react';
import { motion } from 'framer-motion';

// Components

// Animations
import { pushItemLeft } from '../../data/animationData';
import { pushItemUp } from '../../data/animationData';
import { parentAnimation } from '../../data/animationData';

export default function RedLinedSubHeader({text, fg_color}) {
    return (
        <motion.div className="red-lined-text" variants={parentAnimation} initial="before" animate="after">
            <motion.span className="red-lined-text-line" variants={pushItemLeft}></motion.span>
            <motion.h2 className={`red-lined-text-text ${fg_color}`} variants={pushItemUp}>{text}</motion.h2>
        </motion.div>
    );
}