// Packages
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import RedLinedSubHeader from './RedLinedSubHeader';
import TeamGaloImage from './TeamGaloImage';

export default function InfoSection({title, text, fg_color, bg_color, reverse, image}) {

    const infoImage = useStaticQuery(graphql`
        query infoImages {
            infoImgs: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
            nodes {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 75)
                id
                }
                relativePath
            }
            }
        }
    `).infoImgs.nodes.find(i => i.relativePath == image).childImageSharp.gatsbyImageData;

    return (
        <div className={`info-section ${reverse ? 'reverse-info-section' : ''}`}>
            <div className={`info-section-texts content-wrapper ${bg_color} ${fg_color}`}>
                <RedLinedSubHeader
                    text={title}
                    fg_color={fg_color}
                ></RedLinedSubHeader>
                <p>{text}</p>
            </div>
            <div className="info-section-image-wrapper">
                <div className="info-section-image">
                    <GatsbyImage className="info-section-image" image={infoImage} alt={image}></GatsbyImage>
                </div>
                <div className="info-section-image-overlay">

                </div>
            </div>
            
        </div>
    );
}